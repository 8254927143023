import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import GetInvolved from "./pages/GetInvolved";
import HallOfFame from "./pages/HallOfFame";

function App() {

  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>

        <Route path="/get-involved" element={<Layout />}>
          <Route index element={<GetInvolved />} />
          {/* <Route path="view/:id" element={<ViewDev />} /> */}
        </Route>

        <Route path="/hall-of-fame" element={<Layout />}>
          <Route index element={<HallOfFame />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
