const mentors = [
    {
        "name": "Chukwudi Ibeche",
        "image": "./mentor-images/Chukwudi-Ibeche.jpeg",
        "role": "Founder & Mentor, 2023",
        "stack": ["Node.js", "Typescript", ".Net", "React", "Angular", "AWS"],
        "bio": {
            "paragraph1": "Chukwudi Ibeche is a software engineer with extensive experience in building innovative solutions and solving real-world problems across fintech, e-commerce, health tech, and other industries. He brings a wealth of knowledge and a commitment to building and teaching technology, helping junior engineers achieve accelerated career growth.",
            "paragraph2": "Through HitDevs, Chukwudi combines his technical expertise with a passion for teaching and mentorship, creating a supportive environment where tech newbies can grow and succeed.",
        },
        "handles": [
            {
                "app": "Linkedin",
                "logo": "bi bi-linkedin fs-5",
                "url": "https://www.linkedin.com/in/chukwudiibeche"
            },
            {
                "app": "Facebook",
                "logo": "bi bi-facebook fs-5",
                "url": "https://www.facebook.com/ibechegoodness"
            },
            {
                "app": "X",
                "logo": "bi bi-twitter-x fs-5",
                "url": "https://x.com/Maazi_Goodybag"
            }
        ]
    },
    {
        "name": "Chisom Jude",
        "image": "./mentor-images/Chisom-Jude.jpg",
        "role": "Mentor, 2023",
        "stack": ["Microsoft Azure", "AWS", "Linux", "Windows Servers", "Docker", "Kubernetes", "Python"],
        "bio": {
            "paragraph1": "Chisom Jude is a seasoned DevOps Engineer and Site Reliability Engineer currently with Interswitch Group, Africa's leading payment solutions provider. With a strong passion for technology and community building, Chisom is dedicated to developing the next generation of tech talent.",
            "paragraph2": "An active contributor to various tech communities, Chisom provides training and mentorship through platforms like Spice Technologies, She Code Africa Lagos, and other initiatives, inspiring and empowering young minds in the tech industry.",
        },
        "handles": [
            {
                "app": "Linkedin",
                "logo": "bi bi-linkedin fs-5",
                "url": "https://www.linkedin.com/in/chisomjude"
            },
        ]
    },
    {
        "name": "David Okolie",
        "image": "./mentor-images/David-Okolie.jpeg",
        "role": "Mentor, 2024",
        "stack": ["Node.js (Express.js, Nestjs)", "Python (Django, Flask)", "GraphQL", "AWS"],
        "bio": {
            "paragraph1": "David Okolie is a skilled Backend Engineer with years of experience designing and building scalable backend systems for web and mobile applications. Transitioning from a successful career in architecture and project management, David brings a unique problem-solving mindset to software engineering.",
            "paragraph2": "David has worked on complex projects involving payment systems, digital wallets, investment systems, gamifications and secure third-party integrations. He is passionate about building innovative solutions and contributing to dynamic, mission-driven teams in fintech, e-commerce, and beyond.",
        },
        "handles": [
            {
                "app": "Linkedin",
                "logo": "bi bi-linkedin fs-5",
                "url": "https://www.linkedin.com/in/david-okolie"
            },
            {
                "app": "X",
                "logo": "bi bi-twitter-x fs-5",
                "url": "https://x.com/David_Inkheart"
            }
        ]
    },
    {
        "name": "David Omole",
        "image": "./mentor-images/David-Omole.jpeg",
        "role": "Mentor, 2024",
        "stack": ["React", "next js", "Typescript", "Node js", "Firebase"],
        "bio": {
            "paragraph1": "Ayomikun Omole is a frontend engineer with a proven track record of leading complex projects, solving technical challenges, and delivering user-focused solutions. With experience in fintech and e-commerce, Ayomikun has successfully launched products, integrated systems, and collaborated with cross-functional teams to drive results.",
        },
        "handles": [
            {
                "app": "Linkedin",
                "logo": "bi bi-linkedin fs-5",
                "url": "https://www.linkedin.com/in/david-omole-aa3064147"
            },
            {
                "app": "Instagram",
                "logo": "bi bi-instagram fs-5",
                "url": "https://instagram.com/hellomikun"
            }
        ]
    },
    {
        "name": "Stephen Mgbeojikwe",
        "image": "./mentor-images/Stephen-Mgbeojikwe.jpeg",
        "role": "Mentor, 2023",
        "stack": ["React", "React native", "Angular", "Typescript", "Ionic", "Flutter", "Node js", "MongoDB"],
        "bio": {
            "paragraph1": "Stephen Mgbeojikwe is a Software Developer with over five years of experience and a strong understanding of mobile app architecture and design patterns, REST APIs, the document request model, and offline storage. Stephen brings extensive expertise in delivering high-quality software solutions.",
            "paragraph2": "Stephen specializes in building robust applications on mobile platforms. His strong analytical mindset and commitment to teamwork contributes to successful project outcomes.",
        },
        "handles": [
            {
                "app": "Linkedin",
                "logo": "bi bi-linkedin fs-5",
                "url": "https://www.linkedin.com/in/stephen-mgbeojikwe"
            },
            {
                "app": "X",
                "logo": "bi bi-twitter-x fs-5",
                "url": "https://x.com/StephenMgb47482"
            },
            {
                "app": "Insstagram",
                "logo": "bi bi-instagram fs-5",
                "url": "https://www.instagram.com/stevolala11"
            }
        ]
    },
]

export default mentors;