import { Link } from "react-router-dom";

const Footer = () => {

    return ( 
        <footer>
            <div className="bg-dark text-light p-5 pb-3">
                <div  className="text-center">
                    <div className="container mb-5 ps-5">

                        <div className="row">
                            
                            <div className="col-lg-6 mb-4">
                                <a className="icon-link d-block" href="/#about-us"><span className="ps-2">About us</span></a>
                                <a className="icon-link d-block" href="/get-involved"><span className="ps-2">Get involved</span></a>
                                <a className="icon-link d-block" href="/hall-of-fame"><span className="ps-2">Hall of fame</span></a>
                                <a className="icon-link d-block" href="https://forms.gle/q3ZiUBdKFs4a96hw5" target="_hitdevs" rel="noreferrer"><span className="ps-2">Contact us</span></a>
                            </div>

                            {/* <div className="col mb-4">
                                <a className="icon-link d-block" href="#">
                                    <img src="../email.png" className="bi" aria-hidden="true" alt="icon" />
                                    <span className="ps-2">facebook</span>
                                </a>
                                <a className="icon-link d-block" href="#">
                                    <img src="../location.png" className="bi" aria-hidden="true" alt="icon" />
                                    <span className="ps-2">twitter</span>
                                </a>
                                <a className="icon-link d-block" href="#">
                                    <img src="../phone.png" className="bi" aria-hidden="true" alt="icon" />
                                    <span className="ps-2">linkedin</span>
                                </a>
                                <a className="icon-link d-block" href="#">
                                    <img src="../phone.png" className="bi" aria-hidden="true" alt="icon" />
                                    <span className="ps-2">youtube</span>
                                </a>
                            </div> */}

                            <div className="col-lg-6">
                                <a className="icon-link d-block">
                                    <img src="../assets/email.png" className="bi" aria-hidden="true" alt="icon" />
                                    <span className="ps-2">info.hitdevs@gmail.com</span>
                                </a>
                                <a className="icon-link d-block">
                                    <img src="../assets/location.png" className="bi" aria-hidden="true" alt="icon" />
                                    <span className="ps-2">Lagos, Nigeria</span>
                                </a>
                                <a className="icon-link d-block">
                                    <img src="../assets/phone.png" className="bi" aria-hidden="true" alt="icon" />
                                    <span className="ps-2">+234 8125960399</span>
                                </a>
                            </div>
                        </div>

                    </div>

                    <div> &copy; Copyright 2024. All rights reserved.</div>
                </div>
            </div>
        </footer>
    );
  };
  
  export default Footer;