
const MentorCard = (props) => {
    return (
      <>
        <div className="col-lg-6 px-5">
            <div className="rounded-4 mx-auto border border-3 border-warning-subtle mentor-card position-relative">
                
                <div className="row">
                    <div className="col-sm-6 position-relative mentor-heading">
                        <div className="mentor-img-div">
                            <img src={props.mentor.image} alt="" className="border border-3 border-warning rounded-pill bg-light img-fluid"/>
                        </div>
                    </div>

                    <div className="col-sm-6 ps-4">
                        <h5 className="mt-4 fw-bold lh-1">{props.mentor.name}</h5>
                        <h6 className="lh-1">{props.mentor.role}</h6>
                        <div className="lh-1 mentor-stack-text"> { props.mentor.stack.join(", ") + "..." } </div>
                    </div>
                </div>

                <div className="p-4 mentor-card-text">
                    <p>{props.mentor.bio.paragraph1}</p>
                    <p>{props.mentor.bio.paragraph2}</p>
                </div>
                <div className="d-flex mentor-socials-div">
                    {
                        props.mentor.handles.map((handle, i) => {
                            return <div className="ms-3 rounded-pill mentor-socials bg-warning-subtle">
                                <a href={handle.url} className=" text-dark" target="_hitdevs"> <i className={handle.logo}></i> </a>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
      </>
    );
  };
  
  export default MentorCard;