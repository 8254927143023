
const HeroSection = () => {
    return (
      <>
        <div className="row">
          <div className="col-6">
            <img src="./assets/floater1.png" alt="" className="floating-img1"/>
          </div>
          <div className="col-4">
            <img src="./assets/floater2.png" alt="" />
          </div>
          <div className="col-2 text-end">
            <img src="./assets/floater3.png" alt="" />
          </div>
        </div>
          
         <div className="row">
          <div className="col-lg-8">
            <h2 className="heading">Access Tech Mentorship</h2>
            <h2 className="heading2">from anywhere... at no cost</h2>
            <p className="mt-3">
             Join a supportive community of experienced engineers ready to guide you on your journey. HitDevs offers the mentorship and resources you need to break into tech, whenever you're ready, at absolutely no cost.
            </p>
              <div>
                <a href="https://forms.gle/U2zWFe1eB16uS274A" target="_hitdevs" rel="noreferrer">
                  <button type="button" className="btn btn-dark py-3 px-5 rounded-pill action-btn m-2">Volunteer with us</button>
                </a>
                <a href="https://forms.gle/MNzVwBnuWa1Ht4F27" target="_hitdevs" rel="noreferrer">
                  <button type="button" className="btn btn-outline-warning py-3 px-5 text-black rounded-pill action-btn m-2">Partner with us</button>
                </a>
              </div>

              <div className="row">
                <div className="col-9">
                  
                </div>
                <div className="col-3">
                  <img src="./assets/floater3.png" alt="" />
                </div>
              </div>
          </div>
          <div className="col-lg-3">
            <div className="hero-image-div pb-4 bg-dark mx-auto" >
              <img src="./banner-images/hero-image.jpg" className="hero-img img-fluid ms-4" alt="" ></img>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default HeroSection;