import { useState } from "react";
import HeroSection from "../components/HeroSection";
import AboutUs from "../components/AboutUs";
import Testimonials from "../components/Testimonials";
import testimonials from "../data/testimonials"


const Home = () => {

  const [testimonial, setTestimonial] = useState(testimonials[0]);

  function previousTestimonial (index) {
    setTestimonial(testimonials[index])
  }

  function nextTestimonial (index) {
    setTestimonial(testimonials[index]);
  }

  return (
    <div className="page container home">
      <HeroSection />
      <AboutUs />
      <Testimonials
        testimonial={testimonial}
        onBackClick={previousTestimonial}
        onFrontClick={nextTestimonial}
      />
    </div>
  );
};
  
export default Home;