
const GetInvolvedComponent = (props) => {

    return ( 
        <div className="container get-involved-component rounded-5 text-white">
            <div className="row">
                <div className="col-lg-6 p-5 get-involved-text">
                    <h2 className="heading my-5">{props.heading}</h2>
                    <h5 className="fw-semibold">{props.title}</h5>
                    <p>{props.text}</p>
                    <div>
                        <a href={props.link} target="_hitdevs" rel="noreferrer">
                            <button type="button" className="btn btn-dark rounded-pill py-3 px-5 mt-4" >{props.action}
                                <img width={"10%"} src="./assets/arrow_right.png" alt="" />
                            </button>
                        </a>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="get-involved-img-div">
                        <img src={props.img} className="img-fluid rounded-5" alt="" />
                    </div>
                </div>
            </div>

        </div>
    );
  };
  
  export default GetInvolvedComponent;