
const AboutUs = () => {
    return (
      <>
        <div className="about-us" id="about-us">
          <h3 className="text-center">About us</h3>
          <div className="w-75 mx-auto mb-5 border"></div>

          <div className="row">
            <div className="col-lg-6">
                <div className="border border-light-subtle bg-danger-subtle rounded-3 about-us-text mx-auto">
                  <h5 className="mt-4 text-center">Mission</h5>
                  <div className="p-3">
                    <p>
                      Our mission is simple: to bridge the gap between aspiring developers
                      and the tech industry. HitDevs is committed to helping the next generation
                      of developers acquire the skills, knowledge, and connections they need to
                      secure their first junior or internship role.
                    </p>
                    <p>
                      Through free mentorship, hands-on
                      experience, and a supportive community, we help individuals to take their
                      first steps toward a successful tech career.
                      By empowering others with knowledge and opportunity,
                      we hope to create a ripple effect of transformation,
                      enabling growth and innovation in our communities.
                    </p>
                  </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="border border-light-subtle bg-warning-subtle rounded-3 about-us-text mx-auto">
                    <h5 className="mt-4 text-center">What We Offer</h5>
                    <div className="p-3">
                      <ul>
                        <li>
                          Free Mentorship: Direct guidance from experienced engineers who volunteer
                          their time to help aspiring developers
                        </li>
                        <li>
                          Hands-on Projects: Real-world projects designed to build skills and portfolios.
                        </li>
                        <li>
                          Job Readiness: Expert advice on job searching, interview prep, and career development.
                        </li>
                        <li>
                          Supportive Community: A network of developers and mentors fostering
                          collaboration and mutual growth.
                        </li>
                      </ul>
                    </div>
                </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
                <div className="border border-light-subtle bg-warning-subtle rounded-3 about-us-text mx-auto">
                  <h5 className="mt-4 text-center">Inspiration</h5>
                  <div className="p-3">
                    <div>
                      <p>
                        Inspired by the story of an African American abolitionist, Harriet Tubman,
                        a visionary leader who dedicated her life to guiding others toward freedom, 
                        we aim to be a guiding light for aspiring software developers.
                        Our mission is to help young people escape the cycle of unemployment,
                        unlock their potential, and find their place in the tech industry.
                      </p>
                      <p>
                        Our community is a beacon of hope, providing mentorship, skills, and the
                        support needed to break barriers and create new paths to success in tech.
                      </p>
                    </div>
                  </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="border border-light-subtle bg-danger-subtle rounded-3 about-us-text mx-auto">
                  <h5 className="mt-4 text-center">How It Started</h5>
                  <div className="p-3">
                    <div>
                      <p>
                        HitDevs was founded by a group of Nigerian software engineers, led by Chukwudi Ibeche.
                        They recognized the challenges that many aspiring developers
                        face in finding guidance and real-world experience, and created a platform
                        where experienced engineers volunteer to mentor those new to the field.
                      </p>
                      <p>
                        The initiative is built on the belief that everyone deserves a chance to succeed,
                        and that mentorship is key to unlocking the potential in young minds.
                      </p>
                    </div>
                  </div>
                </div>
            </div>
          </div>

        </div>
      </>
    );
  };
  
  export default AboutUs;