import GetInvolvedComponent from "../components/GetInvolvedComponent";

const GetInvolved = () => {
  const mentorText = "Become a part of the HitDevs community and help shape the careers of aspiring developers. Share your knowledge, offer guidance, and empower the next generation to succeed in tech. Your mentorship can make a difference."
  const partnerText = "Through strategic partnerships, we can offer mentorship, resources, and career opportunities for talents in our community. Join us in shaping the future of tech and empowering the next generation of talent."
  const studentText = "Ready to take your first step into the tech industry? Register for the next cohort and gain access to personalized mentorship, hands-on experience, and the guidance you need to land your first developer role."
  
    return (
      <div className="page container" id="get-involved">

        <ul class="nav nav-tabs nav-fill mt-5" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active text-dark" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Volunteer With Us</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link text-dark" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Partner With Us</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link text-black" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Join The Next Cohort</button>
          </li>
        </ul>

        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
            <GetInvolvedComponent
              heading={"Volunteer as a mentor"}
              title={"Inspire, Guide, and Empower!"}
              text={mentorText}
              link={"https://forms.gle/U2zWFe1eB16uS274A"}
              action={"Sure! Sign me up"}
              img={"./banner-images/mentor.png"}
            />
          </div>
          <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
            <GetInvolvedComponent
              heading={"Partner with us"}
              title={"Invest in the Next Generation of Developers!"}
              text={partnerText}
              link={"https://forms.gle/MNzVwBnuWa1Ht4F27"}
              action={"Sure! Let's do this"}
              img={"./banner-images/partner.png"}
            />
          </div>
          <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
            <GetInvolvedComponent
              heading={"Apply for the Next Cohort"}
              title={"Build Your Future in Tech"}
              text={studentText}
              link={"https://forms.gle/xzkJWYN4PijWbxS39"}
              action={"Register Here"}
              img={"./banner-images/student.png"}
            />
          </div>
        </div>

      </div>
    );
  };
  
  export default GetInvolved;