import Mentors from "../components/Mentors";
import mentors from "../data/mentors"

const HallOfFame = () => {
  
    return (
        <div className="page container" id="hall-of-fame">
            <div className="text-center mt-5 pt-5">
                <h2 className="fw-bold lh-1"> <span className="mx-1">HitDevs'</span> <span className="mx-1">HALL</span> <span className="mx-1">OF</span> <span className="mx-1">FAME</span></h2>
                <h5 className="fw-bold text-warning fst-italic lh-1 heading-font">...not all superheroes wear capes</h5>
            </div>
            <Mentors mentors={mentors} />
        </div>
    );
  };
  
export default HallOfFame;