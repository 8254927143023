import MentorCard from "./MentorCard"

const Mentors = (props) => {
    return (
      <>
        <div className="row">
            { props.mentors.map((mentor, i) => {
                    return <MentorCard mentor={mentor} key={i}/>
                })
            }
        </div>
      </>
    );
  };
  
  export default Mentors;