import { Outlet} from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Layout(props) {
    return (
      <div className="">
        <Header />
        <Outlet />
        <Footer />
      </div>
    );
}

export default Layout;
