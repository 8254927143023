const testimonials = [
    {
        "name": "Malumi BA",
        "image": "./testimonials/Malumi.jpeg",
        "text": {
            "p1": "I recently completed a course with HitDevs and I'm blown away by the quality of the instruction. The instructors were knowledgeable, enthusiastic, and always willing to help. I've learned so much and I'm excited to apply my new skills in the real world."        }
    },
    {
        "name": "Arinze Ezinwa",
        "image": "./testimonials/Arinze.jpeg",
        "text": {
            "p1": "I had the privilege of training under HitDevs, where I gained valuable insights into web development. This experience significantly enhanced my ability to create responsive and user-friendly interfaces. Additionally, I deepened my programming skills by learning key concepts of data structures, which strengthened my problem-solving abilities. I also acquired practical experience in using Git for version control, improving my collaboration and workflow management in team environments."        }
    },
    {
        "name": "Amarachi Edward",
        "image": "./testimonials/Amarachi.jpeg",
        "text": {
            "p1": "Participating in HitDevs mentorship program was a game-changer for me. Unlike random online courses, HitDevs offered a premium learning experience that went beyond just knowledge acquisition. The tutors were exceptionally patient, approachable, and always willing to help me overcome any challenges I faced. The community was stimulating and encouraging, providing a positive and supportive environment that fostered growth.",
            "p2": "The system of accountability in place ensured that I stayed engaged and motivated throughout the program. I was impressed by the high quality of the mentorship, which was provided at no cost. I couldn't have asked for a better introduction to the tech world. HitDevs mentorship program is truly exceptional, and I am grateful to have been a part of it."
        }
    },
]

export default testimonials;