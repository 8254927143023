
const Header = () => {

    return ( 
        <header>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container p-3 header-nav">
                    <a className="navbar-brand" href="/">
                        <img src="../assets/logo.png" alt="Logo"  className="d-inline-block align-text-top w-50 pb-2" />
                    </a> 
                    <button className="navbar-toggler btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav container d-flex justify-content-end">
                            <li className="nav-item active">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/#about-us">About us</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/get-involved">Get Involved</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/hall-of-fame">Hall of fame</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
  };
  
  export default Header;