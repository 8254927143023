import { useState } from "react";
import testimonials from "../data/testimonials"

const Testimonials = (props) => {

    const [testimonialIndex, setTestimonialIndex] = useState(0);
    const [prevBtnClasses, setPrevBtnClasses] = useState("d-none");
    const [nextBtnClasses, setNextBtnClasses] = useState(testimonials.length > 1 ? "" : "d-none");

    function handleBackClick () {
        if (testimonialIndex > 0) {
            const newIndex = testimonialIndex - 1;
            setTestimonialIndex(newIndex);
            props.onBackClick(newIndex)

            if (newIndex <= 0) setPrevBtnClasses("d-none");
            else setPrevBtnClasses("")
        }
        setNextBtnClasses("")
    }

    function handleFrontClick () {
        if (testimonialIndex < testimonials.length -1) {
            const newIndex = testimonialIndex + 1;
            setTestimonialIndex(newIndex);
            props.onFrontClick(newIndex)

            if (newIndex >= testimonials.length -1) setNextBtnClasses("d-none");
            else setNextBtnClasses("")
        }
        setPrevBtnClasses("")
    }

    return ( 
        <div className="container mt-5">
            <h3 className="text-center pt-3">Testimonials</h3>
            <div className="w-75 mx-auto mb-5 border"></div>

            <div className="row">

                <div className="col-lg-3 text-center">
                    <div className="">
                        <div className="">
                            <img src="./assets/testimonial.avif" alt="" className="img-fluid announcement-img" />
                        </div>
                        <div className="pb-3">Some of our partners and beneficiaries have these to say...</div>
                    </div>
                </div>

                <div className="col-lg-9">
                    <div className="border p-2 mx-auto rounded-3 row">
                        <div className="col-sm-4">
                            <div className="testimonial-img-div-container">
                                <div className="testimonial-img-div mx-auto">
                                    <img src={props.testimonial.image} alt="" className="rounded-pill img-fluid bg-light"/>
                                </div>
                                <h6 className="text-center py-2 mt-2">{props.testimonial.name}</h6>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="mt-3"><img width={"8%"} src="assets/qoute.png" alt="" /></div>
                            <div className="px-3 mt-1 testimonial-text">{props.testimonial.text.p1 } <br /> <br /> { props.testimonial.text.p2}</div>
                            <div className="text-end"><img width={"8%"} src="assets/qoute2.png" alt="" /></div>
                        </div>
                    </div>
                    <div className="row px-5 pt-3 mb-5">
                        <div className="col-6">
                            <img className= {prevBtnClasses} width={"25px"} src="./assets/arrow_back.png" alt="" onClick={handleBackClick}/>
                        </div>
                        <div className="col-6 text-end">
                            <img className= {nextBtnClasses} alt="" width={"25px"} src="./assets/arrow_forward.png" onClick={handleFrontClick} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
  };
  
  export default Testimonials;